import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AssortmentFinderComponent } from '../assortment/assortment-finder.component';
import { AssortmentDialogComponent } from '../assortment/assortment.dialog.component';
import { IAssortment } from '../interfaces/IAssortment';
import { IVMagazynBiezacy, OutflowResponseStatus } from '../interfaces/IVMagazyn';
import { IWydanie } from '../interfaces/IWydanie';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-outflow-component',
  templateUrl: './outflow.component.html',
  styleUrls: ['./outflow.component.css']
})
export class OutflowComponent implements OnInit {
  @ViewChild(AssortmentFinderComponent, { static: true }) finder: AssortmentFinderComponent
  @ViewChild(MatTable) outMatTable: MatTable<IVMagazynBiezacy>;
  @ViewChild(MatTable) inMatTable: MatTable<IVMagazynBiezacy>;

  private tableData: MatTableDataSource<IVMagazynBiezacy>;
  private outTableData: MatTableDataSource<IVMagazynBiezacy>;

  assortment: IAssortment;
  selectedRow: IVMagazynBiezacy;
  selection = new SelectionModel<IVMagazynBiezacy>(true, []);
  data: IVMagazynBiezacy[];
  outData: IVMagazynBiezacy[];

  showGrid: boolean = false;
  maxIlosc: number = 0;
  wzIlosc: number = 0;
  outIlosc: number = 0;
  palIlosc: number = 0;
  operStatus: string = "";
  outflowId: number = 0;

  outflowDate: Date = new Date();
  outflowIlosc: number = 0;

  constructor(
    public matDialogRef: MatDialogRef<AssortmentDialogComponent>,
    private dialog: MatDialog,
    private http: HttpClient,
    private route: ActivatedRoute,
    private session: SessionService,
    @Inject('BASE_URL') private baseUrl: string) { }

  outflowDateFormControl = new UntypedFormControl('', [Validators.required]);
  iloscControl = new UntypedFormControl('', Validators.compose([
    Validators.required,
    Validators.min(1),
    Validators.max(this.maxIlosc)
  ]));

  ngOnInit() {
    this.session.verify();
    this.finder.selectedItem.subscribe(value => {
      this.getStock(value);
    });
    this.outData = [];

    this.route.params.subscribe((params: ParamMap) => {
      this.outflowId = params['id'];

      if (this.outflowId) {

        this.http.get<IWydanie[]>(this.baseUrl + 'api/stock/outflowPrint/' + this.outflowId).subscribe(result => {

          result.forEach(d => {
            this.outData.push({
              id: d.id,
              paczka: +this.outflowId,
              paczkaOpis: d.paczkaPrzyjeciaOpis,
              paczkaWydaniaOpis: d.paczkaWydaniaOpis,
              dataPrzyjecia: d.dataPrzyjecia,
              dataWydania: d.dataWydania,
              lokalizacja: d.lokalizacja,
              lokalizacjaOpis: d.lokalizacjaOpis,
              asortyment: d.asortyment,
              asortymentKod: d.kod,
              asortymentOpis: d.nazwa,
              dokumentBrembo: d.dokumentBrembo,
              dataBrembo: d.dataBrembo,
              lotto1: d.lotto1,
              lotto2: d.lotto2,
              cdi: d.cdi,
              cdiBlok: false,
              ilosc: d.ilosc,
              lotto: d.lotto,
              uwagi: d.uwagi
            })
          });
          this.outTableData = new MatTableDataSource<IVMagazynBiezacy>(this.outData);

        }, error => console.error(error));


      }

    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.tableData.filteredData.forEach(row => this.selection.select(row));
  }

  assortmentClick() {
    let dialogRef: MatDialogRef<AssortmentDialogComponent> = this.dialog.open(AssortmentDialogComponent, { width: "960px"});
    dialogRef.componentInstance.onStock = true;
    dialogRef.componentInstance.onSelect.subscribe((value: IAssortment) => {
      this.finder.SetValue(value.kod);
      this.getStock(value);
    });
  }

  iloscChange() {
    this.tableData = new MatTableDataSource<IVMagazynBiezacy>();
    this.wzIlosc = 0;
    this.data.every(item => {
      var exists = this.outData.filter(function (obj) {
        return obj.id == item.id;
      }).length > 0;

      if (exists) {
        return true;
      }

      if (this.wzIlosc + item.ilosc <= this.outflowIlosc) {
        this.tableData.data.push(item);
        this.wzIlosc += item.ilosc;
        return true;
      }
    })
  }

  private getStock(value: IAssortment) {
    if (!value) {
      return;
    }

    this.data = null;
    this.tableData = null;

    this.assortment = value;
    this.maxIlosc = 0;
    this.wzIlosc = 0;
    this.outIlosc = 0;
    this.outflowIlosc = 0;

    this.http.get<IVMagazynBiezacy[]>(this.baseUrl + 'api/stock/outflowGet/' + this.assortment.id).subscribe(result => {
      this.data = result;

      var max = 0;
      this.tableData = new MatTableDataSource<IVMagazynBiezacy>();
      this.data.forEach(item => {
        max += item.ilosc;
        this.tableData.data.push(item);
      });

      this.maxIlosc = max;
      this.showGrid = max > 0;
      this.palIlosc = this.data.length;

      var aid = this.assortment.id;
      this.outData.filter(function (obj) {
        return obj.asortyment == aid;
      }).forEach(item => {
        this.outIlosc += item.ilosc;
      });

      if (max == 0) {
        return;
      }

      var min = this.data[0].ilosc;

      this.iloscControl = new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.min(min),
        Validators.max(this.maxIlosc)
      ]));

    }, error => console.error(error));
  }

  toOutflow() {
    this.outIlosc = 0;    
    this.selection.selected.forEach(item => {
      if (!item.cdiBlok) {
        this.outData.push(item);
        this.outIlosc += item.ilosc;
      }
    });

    this.http.post<IVMagazynBiezacy[]>(this.baseUrl + 'api/stock/outflowGet', this.selection.selected).subscribe(result => {

      result.forEach(item => {
        if (!item.cdiBlok) {
          this.outData.push(item);
          this.outIlosc += item.ilosc;
        }
      });

      this.outTableData = new MatTableDataSource<IVMagazynBiezacy>(this.outData);

      this.outData.forEach(item => {
        this.tableData.data = this.tableData.data.filter(function (obj) {
          return obj.id != item.id;
        });
      });
      this.selection.clear();

    }, error => console.error(error));


  }

  delete(item: IVMagazynBiezacy) {
    debugger;
    this.outData.splice(this.outData.map(p => p.id).indexOf(item.id), 1);
    this.outTableData = new MatTableDataSource<IVMagazynBiezacy>(this.outData);
    if (this.assortment && item.asortyment == this.assortment.id) {
      this.data.push(item);
      this.tableData.data.push(item);
      this.inMatTable.renderRows();
      this.outIlosc -= item.ilosc;
    }
  }

  save() {
    if (!this.outflowDateFormControl.valid) {
      return;
    }
    this.operStatus = "";
    this.outTableData.data.forEach(item => {
      item.dataWydania = this.outflowDate;
      item.paczka = this.outflowId ? +this.outflowId : 0;
    });

    const ofid = this.outflowId == null ? 0 : +this.outflowId;
    this.http.post<OutflowResponseStatus>(this.baseUrl + 'api/stock/outflowsave',
              { data: this.outTableData.data, id: ofid }).subscribe(result => {
      console.debug(result);
      if (result.status) {
        this.operStatus = result.status;
      } else {
        this.operStatus = "OK " + result.data;
        this.data = null;
        this.tableData = null;
        this.outData = null;
        this.outTableData = null;
        window.open(`${this.baseUrl}reports/outflow/${result.data}?code=true`);
      }
    }, result => {
      console.error(result.error.errors);
      console.error(JSON.stringify(this.outTableData.data));
      for (var e in result.error.errors) {
        this.operStatus += result.error.errors[e] + " ";
      }
    });

  }
  

}
